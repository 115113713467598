import React from 'react'
import { Route, Routes } from 'react-router'
import GrievanceDashboardPage from '../app/admin/grievance/pages/GrievanceDashboardPage'
import GrievanceListPage from '../app/admin/grievance/pages/GrievanceListPage'
import GrievanceAdminLandingPage from '../app/dashboard/pages/GrievanceAdminLandingPage'
import { useAuth } from '../context/AuthContext'
import GrievanceInspectorRoutes from './GrievanceInspectorRoutes'
import GrievanceSupervisorRoutes from './GrievanceSupervisorRoutes'
import EpfGrievanceDataGridPage from '../app/admin/grievance/pages/EpfGrievanceDataGridPage'



function GrievanceAdminRoutes() {
  const {authUser} = useAuth();
  return (
    <>
      <Routes>
        {/* Grievance Admin */}
        {authUser.role == 4 && <Route path="/" element={ <GrievanceAdminLandingPage /> }></Route>  }
        <Route path="/grievance/dashboard" element={ <GrievanceDashboardPage /> }></Route>
        <Route path="/grievance/list" element={ <GrievanceListPage /> }></Route>
        <Route path="/epf/grievance/list" element={ <EpfGrievanceDataGridPage /> }></Route>
      </Routes>
      <GrievanceSupervisorRoutes/>
    </>
  )
}

export default GrievanceAdminRoutes