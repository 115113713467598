import api from "../../../api/api";


const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}

const getList = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/epf/grievance/list?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/epf/grievance/details/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const updateAssignee = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/admin/epf/grievance/assign",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const updateStatus = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/admin/epf/grievance/status",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getActivityById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/epf/grievance/activity/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const epfGrievanceServices = {
    getList,
    getById,
    updateAssignee,
    updateStatus,
    getActivityById
}
export default epfGrievanceServices;