import React,{useState} from 'react'
import { Mic, Send } from '@mui/icons-material'
import { Dialog, DialogContent, LinearProgress } from '@mui/material'
import api from '../../../../api/api'
import { useAlert } from '../../../../context/AlertContext';


import MicRecorder from 'mic-recorder-to-mp3';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function ChatAudioInput({callBack}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const [recording,setRecordingStatus] = useState(false);
    const [audioUrl,setAudioUrl] = useState(null);
    const [blobData,setBlobData] = useState(null);
    const [blocked,setBlocaedStatus] = useState(true);

    
    const onStart = () => {
        Mp3Recorder.start().then(()=>{
            setAudioUrl(null);
            setBlobData(null);
            setRecordingStatus(true);
        })
    }

    const onStop = () => {
        Mp3Recorder.stop().getMp3()
        .then(([buffer, blob]) => {
            setBlobData(blob);
            const _blobURL = URL.createObjectURL(blob)
            setAudioUrl(_blobURL);
            setRecordingStatus(false);
        }).catch((e) => console.log(e));
    }

    const sendAudio = () =>{
        const chatUserInfo =JSON.parse(sessionStorage.getItem("chatUserInfo")); 
        let formData = new FormData();
        formData.append('fileType', "audio");
        formData.append('extension', "mp3" );
        formData.append('phone',chatUserInfo.phoneNo)
        formData.append('tmpFile', blobData );
        uploadToServer(formData);
    }

    const uploadToServer = (formData) =>{
        api.post("admin/simulator/chat/file",formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then( (res)=>{  
            console.log("here in upload aws success",res.data);
            callBack(res.data.filePath);
            setDialogStatus(false);
        }).catch( (error) => {
            console.log(error);
        })
    }

    return (
        <>
            <button className='btn btn-outline-success' href="#mic-input"  onClick={()=>setDialogStatus(true)}><Mic /></button>
            <Dialog
                open={dialogStatus}
                    keepMounted
                    onClose={()=>setDialogStatus(false)}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <DialogContent>
                    <div className="text-center">
                        {!recording && <button type="button" className="btn btn-danger" onClick={onStart} >Start Recording</button>}
                        {recording && 
                            <div className="mt-3">
                                <h3 className="mb-3">Recording Audio...</h3>
                                <LinearProgress color="warning" /><button type="button" className="btn btn-danger mt-3" onClick={onStop} >Stop Recording</button>
                            </div>
                        }
                        {audioUrl !== null &&
                            <div className="mt-3 text-center">
                                <div>
                                    <audio controls>
                                        <source src={audioUrl} />
                                    </audio>
                                </div>
                                <div>
                                    {/* <a download="text.mp3" href={audioUrl} className="btn btn-outline-primaru">Download</a>  */}
                                    <button className="btn btn-success float-end" onClick={sendAudio} ><Send/> Send</button>
                                </div>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>	
    )
}

export default ChatAudioInput