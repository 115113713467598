import React,{useState} from 'react'
import authServices from '../authServices';
import { useAlert } from '../../../context/AlertContext';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';


function AdminLoginPage() {
    const {AppAlert} = useAlert()
    const {setAuthUser,setIsLoggedIn} = useAuth();
    const navigate = useNavigate();
    const [errorMsg,setErrorMsg] = useState("");
    const [otpSent,setSentOtpStatus] = useState(false);
    const [email,setEmail] = useState("");
    const [otp,setOtp] = useState("");
    

    const requestOtp = () =>{
        authServices.adminRequestOtp({"username":email}).then(res=>{
            AppAlert(res.message,"success");
            setSentOtpStatus(true);
        }).catch(error=>{
            AppAlert(error.message,"error")
        })
    }
    const verifyOtp = () =>{
        authServices.adminVerifyOtp({"username":email,"otp":otp}).then(res=>{
            if(res.data !== undefined){
                setAuthUser(res.data);
                setIsLoggedIn(true);
                navigate("/")
            }
        }).catch(error=>{
            AppAlert(error.message,"error")
        })
    }

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-8 text-center">
                    <img src='/images/gtoken-logo.jpg' style={{width:"50%"}} />
                </div>
                <div className="col-md-4 ">
                    <div className="form-floating mb-3">
                        <input type='email' className='form-control' value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                        <label htmlFor="floatingInput">Username</label>
                    </div>
                    {otpSent &&
                        <div className="form-floating mb-3">
                            <input type='otp' className='form-control' value={otp} onChange={(e)=>{setOtp(e.target.value)}}  />
                            <label htmlFor="floatingInput">OTP</label>
                        </div>
                    }
                    <div className="row">
                        <div className='col-md-12'>
                            {!otpSent &&
                                <button className='btn btn-primary float-end' disabled={email===""} onClick={requestOtp} >
                                    Request OTP
                                </button>  
                            }
                            {otpSent &&
                                <button className='btn btn-primary' disabled={email===""} onClick={verifyOtp} >
                                    Submit
                                </button>  
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AdminLoginPage