import React, { useEffect, useState, useRef } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import ResponseFilter from '../components/ResponseFilter'
import responseMessageServices from '../responseMessageServices';
import { useAlert } from '../../../../context/AlertContext';
import { Link } from 'react-router-dom';
import useStaffList from '../../../../hooks/useStaffList';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { MessageTemplateMinified } from '../components/MessageTemplate/MessageTemplate';


const RenderLink = (params) =>{
    return (
        <Link target='_blank' to={`/admin/response-message/details/${params.data.id}`}>{params.data.title}</Link>
    )
}

const RenderMessage = (params) =>{
    return (
		<>
			<MessageTemplateMinified message={JSON.parse(params.data.formValues)} messageType={params.data.messageType} />
		</>

    )
}

const messageValue = (params) =>{
	if(params.data.messageType==="text"){
		return JSON.parse(params.data.formValues).messageBody;
	}
	
	if(params.data.messageType==="interactiveReply"){
		return JSON.parse(params.data.formValues).headerText+" "+JSON.parse(params.data.formValues).bodyText;
	}

	if(params.data.messageType==="interactiveList"){
		return JSON.parse(params.data.formValues).headerText+" "+JSON.parse(params.data.formValues).bodyText;
	}
}


function ResponseMessageGridPage() {
	

    const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);
    const [staffList,getStaffLabel] = useStaffList();

	const {AppAlert} = useAlert()
	const [filters,setFilters] = useState(null);
	const [responseMessageList,setResponseMessageList] = useState(null);
	const [loading,setLoadingStatus] = useState(false);

	const getResponseMessageList = () =>{
		if(!filters){
			return false;
		}
		setLoadingStatus(true);
			responseMessageServices.getList(filters).then(res=>{
			setResponseMessageList(res.data);
		}).catch(error=>{
			AppAlert(error.msg,"error")
		}).finally(()=>{
			setLoadingStatus(false)
		})
	}

	useEffect(()=>{
		getResponseMessageList()
	},[filters])



	const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
            buttons: ['apply'],
        },
        resizable: true,
        width:140,
        filter:true,
        sortable: true,
    });

    const rowClassRules = {
        //'bg-danger text-white' : params => params.data.grievanceStatus === 1,
        'bg-warning' : params => params.data.grievanceStatus === 2,
        'bg-success text-white': params => params.data.grievanceStatus === 3,
        'bg-danger text-white': params => params.data.grievanceStatus === 4,
        
    };

    const [columnDefs,setColumnDef] = useState(null);
    const prepairColumnDef = () => {
        let _columnDefs = [
            {width:100, field:"id",headerName: "ID",cellRenderer: RenderLink},
			{width:1000,headerName: "Message",cellRenderer: RenderMessage, valueGetter : params => messageValue(params) },
			{width:180, headerName: "Created By", valueGetter: params => getStaffLabel(params.data.userId)},
			{width:180, headerName: "Last Updated", valueGetter: params => moment(params.data.updated_at).format("lll")},
        ];
        setColumnDef(_columnDefs);
    }

    useEffect(()=>{
        if(masters && staffList) {
          prepairColumnDef()
        }
      },[masters,staffList])

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    };


	return (
		<AppLayout>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-12'>
						<ResponseFilter filters={filters} setFilters={setFilters} />
					</div>
				</div>
				<div className='row'>
					<div className='col-md-12'>
						<div className="ag-theme-alpine" style={{height:600}}>
							{columnDefs !== null &&
							<AgGridReact
								ref={gridRef}
								rowData={responseMessageList}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								onGridReady={getResponseMessageList}
							>
							</AgGridReact>
							}
						</div>
					</div>
				</div>
			</div>
			
		</AppLayout>
	)
}

export default ResponseMessageGridPage