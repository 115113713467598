import React,{useState} from 'react'

import { CircularProgress } from '@mui/material';
import { useAlert } from '../../../../context/AlertContext';
import { AccessTime, CalendarMonth, Send } from '@mui/icons-material';
import moment from 'moment';
import useStaffList from '../../../../hooks/useStaffList';
import eprGrievanceRemarkServices from '../epfGrievanceRemarkServices';
import epfGrievanceServices from '../epfGrievanceServices';

function EpfGrievanceRemark({grievanceDetails}) {
    const {AppAlert} = useAlert();
    const [staffList,getStaffLabel] = useStaffList();
    const [remark,setRemark] = useState("");
    const [saving,setSavingStatus] = useState(false);
    
    const handleRemarkInput = (e) => {
        let val = e.target.value;
        if(val.length <= 200) {
            setRemark(val)
        }
    }

    const saveRemark = () =>{
        if(remark.length < 4){
            AppAlert("Remark length must be atleast 4 characters long ","error")
            return false;
        }
        
        let payload ={
            grievanceId:grievanceDetails.id,
            remark:remark
        }
        setSavingStatus(true);
        eprGrievanceRemarkServices.save(payload).then(res=>{
            setRemark("");
            AppAlert("Remark Saved");
            getRemarkList();
        }).catch(error=>{

        }).finally(()=>{
            setSavingStatus(false);
        })
    }


    const saveAndReply = () =>{
        if(remark.length < 4){
            AppAlert("Remark length must be atleast 4 characters long ","error")
            return false;
        }
        
        let payload ={
            grievanceId:grievanceDetails.id,
            remark:remark
        }
        setSavingStatus(true);
        eprGrievanceRemarkServices.saveAndReply(payload).then(res=>{
            setRemark("");
            AppAlert("Remark Saved");
            getRemarkList();
        }).catch(error=>{

        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const [remarkList,setRemarkList] = useState(null)

    const getRemarkList = () =>{
        eprGrievanceRemarkServices.getRemarkByGrievanceId(grievanceDetails.id).then(res=>{
            setRemarkList(res.data);
        }).catch(()=>{

        })
    }

    return (
        <>
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='form-floating'>
                                <textarea
                                    value={remark}
                                    className='form-control'
                                    onChange={handleRemarkInput}
                                    placeholder=''
                                    style={{height:100}}
                                />
                                <label>
                                    <span> Your Remark</span>
                                    {remark.length > 0 && <span className='bg-success text-white ms-1 p-1 rounded fw-bold'>{200-remark.length} / 200</span> }
                                </label>
                            </div>
                        </div>
                        <div className='col-md-12 mb-2'>
                            {saving && <span className='float-end'><CircularProgress/></span>}
                            {!saving && <button className='btn btn-primary float-end m-2' onClick={saveRemark} >Save Remark</button> }
                            {!saving && <button className='btn btn-outline-primary float-end m-2 ' onClick={saveAndReply} > <Send/> Save And Reply To Customer</button> }
                        </div>
                    </div>

                    <div className='row'>
                        {remarkList == null &&
                            <div className='col-md-12'> 
                                <button className='btn btn-primary mb-3 ' onClick={getRemarkList} >View Remarks</button>
                            </div>
                        }
                        <div className='col-md-12'>
                            
                                {remarkList && remarkList.map(remarkDetails=>
                                    <div className='card mb-2'>
                                        <div className='card-body'>
                                            <span className='badge bg-dark'><CalendarMonth/> {moment(remarkDetails.created_at).format("DD-MMM YYYY") }</span>
                                            <span className='badge bg-dark ms-2'><AccessTime/> {moment(remarkDetails.created_at).format("hh:mm A") }</span>
                                                <div className='mt-3'><span >{remarkDetails.remark}</span></div>
                                                <span className="blockquote-footer">{getStaffLabel(remarkDetails.userId)} </span>
                                        </div>
                                    </div>
                                )}
                            
                        </div>
                    </div>
        </>
    )
}

export default EpfGrievanceRemark