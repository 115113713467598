import React,{useEffect, useState} from 'react'
import AppSelect from '../../../../components/AppInput/AppSelect';
import { Search } from '@mui/icons-material';

function ResponseFilter({filters,setFilters}) {

    const [keywordInput,setKeywordInput] = useState("");
    const [messageType,setMessageType] = useState(null);

    const messageTypeList = [
        {value:null,label:"-- Select One --"},
        {value:"interactiveList",label:"Menu List"},
        {value:"interactiveReply",label:"Reply Button"},
        {value:"text",label:"Text / Link message"},
        {value:"locationMessage",label:"Location Message"},
        {value:"imageMessage",label:"Image"},
        {value:"changeFlow",label:"Change Flow"},
    ]

    const getMessageList = () =>{
        if(messageType !== null && messageType.value !== null){
            let _filters = {
                keyword : keywordInput,
                messageType:  messageType.value
            }
            setFilters(_filters)
        }
    }

    useEffect(()=>{
        getMessageList();
    },[messageType])

    

    return (
        <>
            <div className='row my-2'>
                <div className='col-md-6'>
                    <AppSelect
                        options={messageTypeList}
                        value={messageType}
                        setValue={setMessageType}
                        label="Select Message Type" 
                        optionLabel = "label"
                    />
                </div>
                <div className='col-md-6'>
                    <div className="input-group">
                        <input type="text" className="form-control py-3" placeholder="Search here..." 
                            value={keywordInput} 
                            onChange={(e)=>setKeywordInput(e.target.value)}
                            onKeyUp={ (e) =>{
                                if(e.key === 'Enter') {
                                    e.preventDefault();
                                    getMessageList();
                                }
                            }}
                        />
                        <button className="btn btn-primary px-3" type="button" onClick={getMessageList}><Search/></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResponseFilter