import React,{useState} from 'react'
import { PinDrop } from '@mui/icons-material'
import { Dialog, DialogContent } from '@mui/material'
import useCordinates from '../../../../hooks/useCordinates';

function ChatLocationInput({callBack}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const cordinates = useCordinates();
    const sendLocation = () =>{
        if(navigator.geolocation){
			navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
				if (permissionStatus.state === 'denied') {
				  alert('Please allow location access.');
				  window.location.href = "app-settings:location";
				} else {
				  	//navigator.geolocation.getCurrentPosition(success, error);
				  	
                        callBack(cordinates);
                        setDialogStatus(false);
					
				}
			  });
		}
    }
    return (
        <>
            <a className="dropdown-item" href="#location"  onClick={()=>setDialogStatus(true)}><PinDrop /> Location</a>
            <Dialog
                open={dialogStatus}
                    keepMounted
                    onClose={()=>setDialogStatus(false)}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <DialogContent>
                <div className="row">
                    <button className='btn btn-primary' onClick={sendLocation} >Share My Current Location</button>
                </div>
                </DialogContent>
            </Dialog>
        </>	
    )
}

export default ChatLocationInput