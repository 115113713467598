import React,{useState,useEffect} from 'react'
import { useAlert } from '../../../../context/AlertContext';
import { Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import intentServices from '../intentServices';

function IntentFormComponent({responseId}) {

    const {AppAlert} = useAlert();
    const [intentInput,setIntentInput] = useState("");
    const [userQueryList,setUserQueryList] = useState([]);
    const [saving, setSavingStatus] = useState(false);
    


    const getIntentByResponseId = () =>{
        setUserQueryList(null);
        intentServices.getIntentByResponseId(responseId).then((res)=>{
            setUserQueryList(res.data);
        }).catch(errr=>{
            console.log("Somthing went wrong while loading intent please try again")
            setUserQueryList([]);
        }).finally(()=>{
        })
    }

    useEffect(()=>{
        getIntentByResponseId()
    },[responseId])


    const addToUserQueryList = () =>{
        setSavingStatus(true);
        intentServices.save({userQuery:intentInput,responseMessageId:responseId}).then(res=>{
            setIntentInput("");
            AppAlert("New Intent Created");
            getIntentByResponseId();
        }).catch(error=>{
            AppAlert( error.msg, "error")
        }).finally(()=>{
            setSavingStatus(false)
        })
    }

    const removeFromUserQueryList = (id) =>{
        intentServices.remove(id).then(res=>{
            AppAlert("Removed Successfully");
            getIntentByResponseId();
        }).catch(error=>{
            AppAlert("Somthing went wrong while remove intent please try again","error")
        }).finally(()=>{
        })
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <h4>User Intents "Show this message when user query these keywords" </h4> 
                </div>
                <div className='col-md-12'>

                    {!userQueryList &&
                        <div className='text-center'><CircularProgress/></div>
                    }

                    {userQueryList && userQueryList.length > 0 && 
                        <div className='col-md-12'>
                            <div className='border border-dark rounded p-2'>
                                {userQueryList.map((intentMessage) =>
                                    <Chip key={intentMessage.id} label={intentMessage.userQuery} className='me-1' onDelete={()=>removeFromUserQueryList(intentMessage.id)} />
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div className='col-md-12'>
                    <div className="input-group my-3">
                        <input type="text" className="form-control" placeholder="User Query" 
                            value={intentInput} 
                            onChange={(e)=>setIntentInput(e.target.value)}
                            onKeyUp={ (e) =>{
                                if(e.key === 'Enter') {
                                    e.preventDefault();
                                    addToUserQueryList();
                                }
                            }}
                        />
                        <button className="btn btn-success" type="button" onClick={addToUserQueryList}>Add</button>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default IntentFormComponent