
import React, { useState,useEffect } from 'react'

import { useAlert } from '../../../../context/AlertContext';
import { CircularProgress } from '@mui/material';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import flowQueryServices from '../flowQueryServices';
import InputOptionsManager from './InputOptionsManager';
import flowResponseServices from '../flowResponeServices';
import InputValidation from './InputValidation';
import MuteConfig from './MuteConfig';



export default function FlowQueryForm({data,callBack,flowId,flowConfigList}) {

    const {AppAlert} = useAlert()
    const [fieldName,setFieldName] = useState("");
    const [fieldLabel,setFieldLabel] = useState("");
    const [messageType,setMessageType] = useState("text");
    const [optionList,setOptionList] = useState([]);

    const [minLength,setMinLength] = useState(0);
    const [maxLength,setMaxLength] = useState(0);
    const [onlyNumeric,setOnlyNumeric] = useState(false);

    const [muteConfig,setMuteConfig] = useState(null);

    const [saving,setSavingStatus] = useState("");
    const [dialogStatus, setDialogStatus] = useState(false);

    const messageTypeList = [
        {value:"text",label:"Text"},
        {value:"interactiveList",label:"Select List"},
        {value:"interactiveReply",label:"Reply Button"},
        {value:"locationRequest",label:"Location Request"},
        {value:"imageRequest",label:"Image Request"},
        {value:"audioRequest",label:"Audio Request"},
    ]


    const autoFillData = ()=>{
		if(data){
			setFieldName(data.fieldName);
            setFieldLabel(data.fieldLabel);
            setMessageType(data.messageType);
            setMinLength(data.minLength);
            setMaxLength(data.maxLength);
            setOnlyNumeric(data.onlyNumeric);
            setMuteConfig(JSON.parse(data.muteConfig));

            let _formValues = JSON.parse(data.formValues);

            if(_formValues.optionList !== undefined){
                setOptionList(_formValues.optionList)
            }
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])

    const onSubmit = () =>{

        let payload = {
            fieldName:fieldName,
            fieldLabel:fieldLabel,
            messageType:messageType,
            formValues: flowResponseServices.prepareFormValues(fieldLabel,optionList,messageType),
            whatsAppResponse :  flowResponseServices.prepareWhatsAppResponse(fieldLabel,optionList,messageType),
            flowId:flowId,
            minLength:minLength,
            maxLength:maxLength,
            onlyNumeric:onlyNumeric,
            messageType:messageType,

            muteConfig: JSON.stringify(muteConfig),
        }
        if(data){
            payload.id = data.id;
        }

        if(messageType === "interactiveList" || messageType === "interactiveReply"){
            if(optionList.length === 0 ){
                AppAlert("No Options Available for interective list","error")
                return false;
            }
        }

        console.log(payload)

        
        setSavingStatus(true)
        flowQueryServices.save(payload).then(res=>{
            AppAlert("New Flow Generated");
            setDialogStatus(false);
            callBack();
        }).catch(error=>{
            AppAlert("Someting","error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        
        
    }

    return (
        <>
            {data === null ?
                <button className="btn btn-primary" onClick={()=>setDialogStatus(true)}>Create New</button>
                :
                <button className="btn btn-primary" onClick={()=>setDialogStatus(true)}>Edit</button>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle>Field Manager</DialogTitle>
                <DialogContent>
                    <div className='row mt-3'>
                        <div className='col-md-4'>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" placeholder=""
                                    value={fieldName}
                                    onChange={(e)=>setFieldName(e.target.value)}
                                />
                                <label >Field Name</label>
                                <small>Variable name</small>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" placeholder=""
                                    value={fieldLabel}
                                    onChange={(e)=>setFieldLabel(e.target.value)}
                                />
                                <label >Field Label</label>
                                <small>Diaplay Text</small>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={messageType} onChange={(e)=>setMessageType(e.target.value)} >
                                    {messageTypeList.map(option=>
                                        <option key={option.value} value={option.value} >{option.label}</option>
                                    )}
                                </select>
                                <label >Input Type</label>
                            </div>
                        </div>

                        { (messageType === "interactiveList" || messageType === "interactiveReply") &&
                            <InputOptionsManager optionList={optionList} setOptionList={setOptionList} messageType={messageType} />
                        }

                        <InputValidation 
                            minLength={minLength}
                            setMinLength={setMinLength}
                            maxLength={maxLength}
                            setMaxLength={setMaxLength}
                            onlyNumeric={onlyNumeric}
                            setOnlyNumeric={setOnlyNumeric}
                        />

                        <MuteConfig flowConfigList={flowConfigList} muteConfig={muteConfig} setMuteConfig={setMuteConfig} />

                    </div>
                </DialogContent>
                <DialogActions>
                <div className='row'>
                        <div className='col-md-6'>
                            {! saving &&
                                <button 
                                    disabled={ fieldName === "" || fieldLabel==="" || messageType==="" }
                                    className='btn btn-primary' type='button' onClick={onSubmit}
                                >{data ? "Update" : "Generate"}</button>
                            }
                            {saving &&  <CircularProgress/> }
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
