import { Person, Phone } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'

function InitiateChatSession({chatuserInfo,setChatUserInfo}) {

    const [phoneNo,setPhoneNumber] = useState("")
    const [userName,setUserName] = useState("");

    const startSession = () =>{
        let _chatUserInfo = {
            phoneNo:phoneNo,
            userName:userName
        }
        sessionStorage.setItem("chatUserInfo",JSON.stringify(_chatUserInfo))
        setChatUserInfo(_chatUserInfo)
    }
    
    return (
        <div className='card'>
            <div className='card-header'></div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><Phone/></span>
                            <input type="text" className="form-control" placeholder="Yor Phone Number" 
                                value={phoneNo}
                                onChange={(e)=>setPhoneNumber(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><Person/></span>
                            <input type="text" className="form-control float-end" placeholder="Your Name" 
                                value={userName}
                                onChange={(e)=>setUserName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-footer'>
                <button className='btn btn-primary' disabled={phoneNo=="" || phoneNo.length != 10 || userName == ""} onClick={startSession} >Start</button>
            </div>
        </div>
    )
}

export default InitiateChatSession