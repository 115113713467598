import { RadioButtonChecked } from '@mui/icons-material'
import moment from 'moment'
import React from 'react'

function IncomingMessageCard({message,dateTime}) {
  return (
    <div className="d-flex justify-content-start me-5">
        <div>
          <div className='card'>
            <div className='card-body'>
              {message.type === "text" && 
                message.text.body
              }
              {message.type === "interactive" && 
                <>
                  {message.interactive.type==="button_reply" &&
                    <div className='btn btn-light border-dark'>{message.interactive.button_reply.title}</div>
                  }
                  {message.interactive.type==="list_reply" &&
                    <div className="d-flex justify-content-between align-items-center">
                      <div><RadioButtonChecked className='me-3'/> </div>
                      <div>
                        <div>{message.interactive.list_reply.title}</div>
                        <div className='text-muted'>{message.interactive.list_reply.description}</div>
                      </div>
                    </div>
                  }

                </>
              }
              
            </div>
          </div>
          <div className='float-start text-muted'>
            {moment(dateTime).fromNow()}
          </div>
        </div>
    </div>
  )
}

export default IncomingMessageCard