import React from 'react'
import { Route, Routes } from 'react-router'
import { useAuth } from '../context/AuthContext'
import GrievanceInspectorLandingPage from '../app/dashboard/pages/GrievanceInspectorLandingPage';
import GrievanceDetailsPage from '../app/admin/grievance/pages/GrievanceDetailsPage'
import UserGrievanceListPage from '../app/admin/grievance/pages/UserGrievanceListPage';
import AssignedGrievanceListPage from '../app/admin/grievance/pages/AssignedGrievanceListPage';
import EpfGrievanceDetailsPage from '../app/admin/grievance/pages/EpfGrievanceDetailsPage';


function GrievanceInspectorRoutes() {
  const {authUser} = useAuth();
  return (
    <>
      <Routes>
        {/* WhatApp User */}
        {authUser.role == 6 && <Route path="/" element={ <GrievanceInspectorLandingPage /> }></Route>  }
        <Route path="/grievance/details/:id" element={ <GrievanceDetailsPage /> }></Route>
        <Route path="/epf/grievance/details/:id" element={ <EpfGrievanceDetailsPage /> }></Route>
        <Route path="/grievance/assigned-list" element={ <AssignedGrievanceListPage /> }></Route>
      </Routes>
    </>
  )
}

export default GrievanceInspectorRoutes
