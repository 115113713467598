import { Add, Delete } from '@mui/icons-material';
import React,{useEffect, useState} from 'react'


function InputOptionsManager({optionList,setOptionList,messageType}) {

  const [optionId,setOptionId]= useState("");
  const [optionTitle,setOptionTitle]= useState("");
  const [optionDescription,setOptionDescription]= useState("");


  const [maxTitleLength,setMaxTitleLength] = useState(20);
  useEffect(()=>{
    if(messageType==="interactiveList"){
      setMaxTitleLength(24)
    }else {
      setMaxTitleLength(20)
    }
  },[messageType])


  

  const addOptionToList = () =>{
    setOptionList(options=> [...options,{optionTitle:optionTitle, optionDescription:optionDescription, optionId:optionId}])
    setOptionTitle("");
    setOptionDescription("");
		setOptionId("");
  }

  const removeOption = (i) =>{
		let _optionList = [...optionList];
        _optionList.splice(i,1)
        setOptionList(_optionList)
	}
  
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          
          <h4>Option Manager</h4>
          <hr/>
        </div>

        <div className='col-md-12'>
          <table className='table'>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder=""
                        value={optionTitle}
                        onChange={(e)=>setOptionTitle(e.target.value)}
                        maxLength={maxTitleLength}
                    />
                    <label >Option Label <span className='text-danger'> ( {maxTitleLength - optionTitle.length} )</span> </label>
                  </div>

                  {messageType==="interactiveList" &&
                    <div className="form-floating">
                      <input type="text" className="form-control" placeholder=""
                          value={optionDescription}
                          onChange={(e)=>setOptionDescription(e.target.value)}
                          maxLength={72}
                      />
                      <label >Option optionDescription <span className='text-danger'> ( {72 - optionDescription.length} )</span> </label>
                    </div>
                  }
                  
                </td>
                <td>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder=""
                      value={optionId}
                      onChange={(e)=>setOptionId(e.target.value)}
                    />
                    <label >Option Id</label>
                  </div>
                </td>
                <td>
                  <button className='btn btn-primary my-2' onClick={addOptionToList} ><Add/></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              {optionList.map( (option,i)=>
                <tr key={i} >
                  <td >
                    {option.optionTitle} <span className='text-danger'>( {option.optionTitle.length} )</span>  
                    {option.optionDescription && option.optionDescription !== "" &&  
                      <>
                      <br/> {option.optionDescription} <span className='text-danger'>( {option.optionDescription.length} )</span>
                      </>
                    }
                  </td>
                  <td >{option.optionId}</td>
                  <td ><button className='btn btn-danger' onClick={()=>removeOption(i)}> <Delete/> </button></td>
                </tr>
              )}
            </tfoot>
          </table>
        </div>
      </div>
      

    </>
    
  )
}

export default InputOptionsManager