import React, { useEffect, useState,useRef } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { Attachment, Send } from '@mui/icons-material';
import ConversationView from '../components/ConversationView';
import chatSimulatorServices from '../simulatorServices';
import { LinearProgress } from '@mui/material';
import { useAlert } from '../../../../context/AlertContext';
import NewIntentComponent from '../components/NewIntentComponent';
import InitiateChatSession from '../components/InitiateChatSession';
import ChatImageInput from '../components/ChatImageInput';
import ChatLocationInput from '../components/ChatLocationInput';
import ChatAudioInput from '../components/ChatAudioInput';


function AdminChatSumulatorPage() {

	const [chatUserInfo,setChatUserInfo] = useState(JSON.parse(sessionStorage.getItem("chatUserInfo"))); 
	
	const {AppAlert} = useAlert();
	const messagesEndRef = useRef(null)
	const [conversationList,setConversationList] = useState([]);
	const [messageInput,setMessageInput] = useState("");
	const [loading,setLoadingStatus] = useState(false);
	const [newIntent,setNewIntent] = useState(null);


	const updateConversation = (message,type,dataType="text") =>{
		let _message = {
			msg : message,
			type : type,
			dataType:dataType,
		}
		//let _conversationList = [...conversationList,_message]; // bug when use this method two time in one function
		setConversationList(_conversationList=>[..._conversationList,_message])		
		setMessageInput("");
	}


	useEffect(()=>{
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	},[conversationList])

	const handleResponse = (res) =>{
		setTimeout(()=>{
			if(res.data==null){
				//AppAlert('No Response Found for "'+res.keyword+'"',"error")
				setNewIntent(res.keyword);
			} else {
				updateConversation(res.data,"i")
			}
			setLoadingStatus(false)
		},[1000])
	}



	const sendTextMessage = () =>{
		let payload = {
			messageType : "text",
			message:messageInput,
			from:chatUserInfo.phoneNo,
			chatUserInfo:chatUserInfo
		}
		setLoadingStatus(true);
		updateConversation(messageInput,"o")
		chatSimulatorServices.submitMessage(payload).then(res=>{
			handleResponse(res);
		}).catch(error=>{
			console.log(error);
			setLoadingStatus(false)
		}).finally(()=>{			
		})
	}


	const sendInterectiveMessage = (option,messageType) =>{
		let payload = {
			messageType : messageType,
			id:option.optionId,
			from:chatUserInfo.phoneNo,
			chatUserInfo:chatUserInfo
		}
		setLoadingStatus(true);
		console.log(option)
		updateConversation(option.optionTitle,"o")
		chatSimulatorServices.submitMessage(payload).then(res=>{
			handleResponse(res);
		}).catch(error=>{
			console.log(error);
			setLoadingStatus(false)
		}).finally(()=>{			
		})
	}

	const sendImageMessage = (url) =>{
		let payload = {
			messageType : "imageRequest",
			url:url,
			from:chatUserInfo.phoneNo,
			chatUserInfo:chatUserInfo
		}
		setLoadingStatus(true);
		updateConversation(url,"o","image")
		chatSimulatorServices.submitMessage(payload).then(res=>{
			handleResponse(res);
		}).catch(error=>{
			console.log(error);
			setLoadingStatus(false)
		}).finally(()=>{			
		})
	}

	const sendAudioMessage = (url) =>{
		let payload = {
			messageType : "audioRequest",
			url:url,
			from:chatUserInfo.phoneNo,
			chatUserInfo:chatUserInfo
		}
		setLoadingStatus(true);
		updateConversation(url,"o","audio")
		chatSimulatorServices.submitMessage(payload).then(res=>{
			handleResponse(res);
		}).catch(error=>{
			console.log(error);
			setLoadingStatus(false)
		}).finally(()=>{			
		})
	}

	const sendLocationMessage = (location) =>{
		let payload = {
			messageType : "locationRequest",
			location:location,
			from:chatUserInfo.phoneNo,
			chatUserInfo:chatUserInfo
		}
		setLoadingStatus(true);
		updateConversation(location,"o","location")
		chatSimulatorServices.submitMessage(payload).then(res=>{
			handleResponse(res);
		}).catch(error=>{
			console.log(error);
			setLoadingStatus(false)
		}).finally(()=>{			
		})
	}

	return (
		<AppLayout>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-12 text-center'>
						
					</div>
					<div className='col-md-4'>
					</div>
					
					{!chatUserInfo && 
						<div className='col-md-4'>
							<InitiateChatSession chatuserInfo={chatUserInfo} setChatUserInfo={setChatUserInfo} />
						</div>
					}
					
					{chatUserInfo &&
					<div className='col-md-4'>
						<div className='card p-0'>
							<div className='card-header bg-primary text-white'>Chat Simulator</div>
							<div className='card-body' style={{height:"75vh", overflowY:"scroll"}}>
								<ConversationView conversationList={conversationList} sendInterectiveMessage={sendInterectiveMessage} />
								{loading && <div className='p-3'><LinearProgress/></div> }
								<div ref={messagesEndRef} />
							</div>
							<div className='card-footer bg-white p-0 border-0'>
								
								<div className="input-group">
									<button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown">
										<Attachment />
									</button>
									<ul className="dropdown-menu">
										<li><ChatImageInput callBack={sendImageMessage} /></li>
										<li><ChatLocationInput callBack={sendLocationMessage} /></li>
									</ul>
									<input type="text" className="form-control bg-light" placeholder="Message"
										value={messageInput}
										onChange={(e)=>setMessageInput(e.target.value)}
										onKeyUp={ (e) =>{
											if(e.key === 'Enter') {
												e.preventDefault();
												sendTextMessage()
											}
										}}
									/>
									<ChatAudioInput callBack={sendAudioMessage} />
									<button className="btn btn-outline-secondary text-success" type="button" id="button-addon3" onClick={sendTextMessage}><Send/></button>
								</div>
							</div>
						</div>
					</div>
					}
					<div className='col-md-4'></div>
					<NewIntentComponent newIntent={newIntent} setNewIntent={setNewIntent} />
				</div>
			</div>
		</AppLayout>
	)
}

export default AdminChatSumulatorPage