import React from 'react'
import {MessageTemplate} from '../../response/components/MessageTemplate/MessageTemplate'




function ConversationView({conversationList,sendInterectiveMessage}) {

  return (
        conversationList.map(conversation =>
        <>
          {conversation.type=='o' ?
            <div className="card me-4 my-2 p-2">
              {conversation.dataType === "image" && <img src={conversation.msg} /> }

              {conversation.dataType === "location" && "You Shared Your Current Location" }
              {conversation.dataType === "audio" && 
                <div>
                  <audio controls>
                    <source src={conversation.msg} />
                  </audio>
                </div>
             }

              {conversation.dataType === "text" && <span>{conversation.msg}</span> }
            </div>
            :
            <div className="ms-4 my-2">
              <MessageTemplate message={conversation.msg.formValues} messageType={conversation.msg.messageType} callBack={sendInterectiveMessage} />
            </div>
          }
        </>
        )
  )
}

export default ConversationView