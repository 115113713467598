import React,{useState,useEffect} from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AccountCircle, AccountTree, Announcement, BookOnline, CalendarMonthOutlined, Campaign, Chat, Dashboard, DataObject, Dataset, DoorSliding, Dvr, Feedback, FollowTheSigns, Home,  Label,  ListAlt,  ManageAccounts, MoreVert, Newspaper, NoteAdd, Notifications, PermMedia, PhoneAndroid, PsychologyAlt, QuestionAnswer, Quiz, ReplyAll, Search, Settings, Store, Tablet, TransferWithinAStation, Tv, WorkOutlined} from '@mui/icons-material';
import useBusinessInfo from '../../hooks/useBusinessInfo';
import { Alert } from '@mui/material';
import moment from 'moment';

function AdminHeader() {

    const {authUser } = useAuth();
    const businessInfo = useBusinessInfo();

    const navigate = useNavigate();
    useEffect(()=>{
        if(businessInfo?.accountStatus === false){
          navigate("/admin/suspend-page");
        }
    },[businessInfo])

    return (
        <>
        {businessInfo?.pendingDays < 15 &&
            <div className="">
                <div className='text-danger text-center'> Your Account will suspend on { moment(businessInfo.validTill).format("ll")} please recharge asap</div>
            </div>
        }
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow mb-3">
            <div className="container-fluid">
                <Link className="me-5" to="/"><img src='/globalinc.png' style={{height:40}} /><img className="ms-2" src='/images/whatsapp.png' style={{height:40}} /></Link> {/* navbar-brand  */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    { authUser.role <= 4 && 
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                        <li className="nav-item"><NavLink className="nav-link text-center" to="/"> <Home/> Home</NavLink></li>
                        { authUser.role <= 3 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="responseControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <ReplyAll/> Chat Bot </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="responseControllDropdown">
                                    {/* <li><NavLink className="dropdown-item" to="/admin/response-message/list"><ListAlt/> Response List</NavLink></li> */}
                                    <li><NavLink className="dropdown-item" to="/admin/chat-simulator"> <QuestionAnswer />Chat Simulator</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/admin/response-message/grid"><ListAlt/> Response List</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/admin/response-message/form"><NoteAdd/> Create New Response</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/admin/new-enquiry"> <Quiz /> New Query List</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/admin/chat-log"> <DataObject /> Chat Logs</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/admin/flow/list"> <AccountTree />Chat Flows</NavLink></li>
                                </ul>
                            </li>
                        }

                        { (authUser.role === 1 || authUser.role === 2 || authUser.role === 4) && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="grievanceControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <Quiz /> Grievance </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="grievanceControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/grievance/list"> <Quiz />Grievance List</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/epf/grievance/list"> <Quiz />Epf Grievance List</NavLink></li>
                                    {authUser.role < 2  && <li><NavLink className="dropdown-item" to="/grievance/data-grid"> <Dataset/> Data Grid</NavLink></li>}
                                    <li><NavLink className="dropdown-item" to="/grievance/search"><Search/>Search Grievance</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/feedback/list"><Feedback/> Feedback</NavLink></li>
                                    
                                </ul>
                            </li>
                        }
                    </ul>
                    }
                    {/* Just for Alignment  */}
                    { authUser.role > 2 &&
                        <div className=" me-auto "></div>
                    } 

                        <ul className="navbar-nav">
                            { authUser.role === 1 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0).toUpperCase()}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    {/*<li><a className="dropdown-item" href="/auth/change-password">Change Password</a></li> */}
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                </div>
            </div>
        </nav>
        </>
    )
}

export default AdminHeader