import React, { useState,useEffect } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { useParams } from 'react-router-dom';

import useSystemMasterList from '../../../../hooks/useSystemMasterList'

import epfGrievanceServices from '../epfGrievanceServices';
import moment from 'moment';
import EpfGrievanceStatusInput from '../components/EpfGrievanceStatusInput';
import EpfGrievanceRemark from '../components/EpfGrievanceRemark';
import EpfActivityList from '../components/EpfActivityList';

function EpfGrievanceDetailsPage() {
	const {id} = useParams();
	const [grievanceDetails,setGrievanceDetails] = useState(null);
	const [masters,getMasterLabel] = useSystemMasterList(['epf-grievance-type','epf-grievance-category','grievance-status']);
	
	const getGreivanceDetails = () =>{
		epfGrievanceServices.getById(id).then(res=>{
			setGrievanceDetails(res.data);
		}).catch(error=>{

		}).finally(()=>{

		})
	}

	useEffect(()=>{
		getGreivanceDetails();
	},[id])

	return (
		<AppLayout>
            {grievanceDetails &&
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
                        {masters && grievanceDetails &&
							<span className='float-end my-2 mx-2'>
								<span className='mx-2 fw-bold'>Current Status : </span>
								<EpfGrievanceStatusInput 
									grievanceDetails={grievanceDetails} 
									masters={masters} 
									getMasterLabel={getMasterLabel} 
									callback={getGreivanceDetails} 
								/>
							</span>
						}
					</div>
				
					<div className='col-md-8'>
						<div className='card'>
                            <div className='card-header'> 
                                <span className="fw-bold">Customer Name : </span>{grievanceDetails.customerName}
                                <span className="fw-bold float-end">{ getMasterLabel("epf-grievance-type", grievanceDetails.epfGrievanceType)}</span>
                            </div>
                            <div className='card-body'>
                                <div className="d-flex justify-content-between">
                                    <span className='fw-bold'>Customer Name</span>
                                    <span>{grievanceDetails.customerName}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className='fw-bold'>
                                        {grievanceDetails.epfGrievanceType == 1 && "UAN #"}
                                        {grievanceDetails.epfGrievanceType == 2 && "PPO Number"}
                                        {grievanceDetails.epfGrievanceType == 3 && "Establishment Number"}
                                    </span>
                                    <span>{grievanceDetails.uid}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className='fw-bold'>Grievance Type</span>
                                    <span>{getMasterLabel("epf-grievance-type", grievanceDetails.epfGrievanceType)}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className='fw-bold'>Grievance Category</span>
                                    <span>{getMasterLabel("epf-grievance-category", grievanceDetails.epfGrievanceCategory)}</span>
                                </div>
                                <div>
                                    <div className='fw-bold'>Details</div>
                                     <div>{grievanceDetails.details}</div>
                                     <hr/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    {grievanceDetails.audioMessage !== null && grievanceDetails.audioMessage !=="" &&
                                    <audio controls>
                                        <source src={grievanceDetails.audioMessage} type="audio/ogg" />
                                    </audio>
                                    }
                                </div>
                                <div className='d-flex justify-content-between'><span className='fw-bold'>Status : </span>{ getMasterLabel("grievance-status",grievanceDetails.grievanceStatus) }</div>
                                <div className='d-flex justify-content-between'><span className='fw-bold'>Reg Date : </span>{ moment(grievanceDetails.created_at).format("LLLL") }</div>
                                <div className='d-flex justify-content-between'><span className='fw-bold'>Last Update : </span>{ moment(grievanceDetails.updated_at).format("LLLL") }</div>
                            </div>
                            { grievanceDetails.grievanceStatus === 1 &&
                                <div className={ 'border border-danger border-2 mb-0'}>
                                </div>
                            }
                            { grievanceDetails.grievanceStatus === 2 &&
                                <div className={ 'border border-warning border-2 mb-0'}>
                                </div>
                            }
                            { grievanceDetails.grievanceStatus === 3 &&
                                <div className={ 'border border-success border-2 mb-0'}>
                                </div>
                            }
                            { grievanceDetails.grievanceStatus === 4 &&
                                <div className={ 'border border-danger border-2 mb-0'}>
                                </div>
                            }
                            
                        </div>
					</div>
					<div className='col-md-4'>

						<div className='row mt-3'>
                            <div className='col-md-12'>
                                {grievanceDetails &&
                                    <EpfGrievanceRemark grievanceDetails={grievanceDetails} />
                                }
                            </div>
						</div>
                        <div className='row mt-3'>
                            <div className='col-md-12'>
								{masters && grievanceDetails &&
									<EpfActivityList grievanceDetails={grievanceDetails} getMasterLabel={getMasterLabel} />
								}
							</div>
                        </div>
					</div>
				</div>
			</div>
            }
		</AppLayout>
	)
}

export default EpfGrievanceDetailsPage