import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import TextMessageForm from '../components/ResponseForm/TextMessageForm';
import InteractiveReplyMessageForm from '../components/ResponseForm/InteractiveReplyMessageForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import responseMessageServices from '../responseMessageServices';
import { useAlert } from '../../../../context/AlertContext';
import InteractiveListMessageForm from '../components/ResponseForm/InteractiveListMessageForm';
import LocationMessageForm from '../components/ResponseForm/LocationMessageForm';
import ImageMessageForm from '../components/ResponseForm/ImageMessage';
import intentServices from '../intentServices';
import ChangeFlow from '../components/ResponseForm/ChangeFlow';



function ResponseMessageFormPage() {

    const [messageType,setMessageType] = useState(null);
    const {id} = useParams();
    const [data,setData] = useState(null);
    const {AppAlert} = useAlert();

    let { search } = useLocation();

    

    const navigate = useNavigate();
    const callBack = (responseId) =>{
        let query = new URLSearchParams(search);
	    let newIntent = query.get('newIntent');
        console.log("add this intent",newIntent)

        if(newIntent!==null){
            intentServices.save({userQuery:newIntent,responseMessageId:responseId}).then(res=>{
                AppAlert("New Intent Created");
                navigate("/admin/response-message/details/"+responseId)
                //window.history.back();
            }).catch(error=>{
                AppAlert( error.msg, "error")
            }).finally(()=>{
                
            })
        } 

        navigate("/admin/response-message/details/"+responseId)
        
        
        
    }


    useEffect(()=>{
        if(id){
            responseMessageServices.getById(id).then(res=>{
                let _data = {id:id,title:res.data.title,responseType:res.data.responseType}
                _data.formValues = JSON.parse(res.data.formValues)
                setData(_data);
                setMessageType(res.data.messageType);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    },[id])

    

    return (
        <AppLayout>
            <div className='container-fluid'>
                {messageType == null &&
                    <div className='row mt-5'>
                        <div className='col-md-12 text-center mb-3'><h2>Create New Response</h2></div>
                        <div className='col-md-12 text-center  mb-3'><h4>Select Response Type</h4></div>
                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("text")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Text Message</div>
                        </div>                        
                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("interactiveReply")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Reply Button</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("interactiveList")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Menu List</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("locationMessage")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Location Messsage</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("imageMessage")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Image</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("changeFlow")} className='text-center bg-danger text-white rounded p-3 m-2' type={"button"}  >Flow Update</div>
                        </div>
                    </div>
                }
                
                <div className='row'>
                    <div className='col-md-12'>
                        {messageType === "text" && <TextMessageForm data={data} callBack={callBack} />}
                        {messageType === "interactiveReply" && <InteractiveReplyMessageForm data={data} callBack={callBack} />}
                        {messageType === "interactiveList" && <InteractiveListMessageForm data={data} callBack={callBack} />}
                        {messageType === "locationMessage" && <LocationMessageForm data={data} callBack={callBack} />}
                        {messageType === "imageMessage" && <ImageMessageForm data={data} callBack={callBack} />}

                        {messageType === "changeFlow" && <ChangeFlow data={data} callBack={callBack} />}

                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default ResponseMessageFormPage