import React, { useEffect, useState } from 'react'
import flowServices from '../flowServices';

function FlowDetailsComponent({flowId}) {

    const [flowDetails,setFlowDetails] = useState(null);

    const getFlowDetails = () =>{
        flowServices.getById(flowId).then(res=>{
            setFlowDetails(res.data);
        }).catch(error=>{

        })
    }

    useEffect(()=>{
        getFlowDetails();
    },[flowId]);

    return (
        flowDetails &&
        <ul className="list-group">
            <li className="list-group-item active">{flowDetails.flowTitle}</li>
            <li className="list-group-item"><span className='fw-bold'>EndPoint : </span> </li>
            <li className="list-group-item">{flowDetails.endPoint}</li>
        </ul>
        
    )
}

export default FlowDetailsComponent