import moment from 'moment'
import React from 'react'
import {MessageTemplate} from '../../response/components/MessageTemplate/MessageTemplate'




function OutgoingMessageCard({message, messageType, dateTime}) {
  return (
    <div className="d-flex justify-content-end ms-5 my-2">
        <div>
            
            <MessageTemplate message={message} messageType={messageType} />
            <div className='float-end text-muted'>
              {moment(dateTime).fromNow()}
            </div>
        </div>
    </div>
  )
}

export default OutgoingMessageCard