import { Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IntentResponseSelector } from '../../response/components/ResponseSelector'
import { Link } from 'react-router-dom'

function NewIntentComponent({newIntent,setNewIntent}) {
    
    const callBack = () =>{
        setNewIntent(null)
    }

    return (
        <Dialog
            open={newIntent !== null}
            keepMounted
            onClose={()=>setNewIntent(null)}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogContent>
                <h3>No response found for "{newIntent}"</h3>
            </DialogContent>
            <DialogActions>
                <Link to={"/admin/response-message/form?newIntent="+newIntent} className='mx-2 btn btn-primary'>Create New</Link>
                <IntentResponseSelector newIntent={newIntent} callBack={callBack} />
                <button className='mx-2 btn btn-outline-danger' onClick={()=>setNewIntent(null)} >Ignore</button>
            </DialogActions>
        </Dialog>
    )
}

export default NewIntentComponent