import React, { useState,useEffect } from 'react'
import { useAlert } from '../../../../context/AlertContext';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import responseMessageServices from '../responseMessageServices';
import ResponseFilter from './ResponseFilter';
import { MessageTemplateMinified } from './MessageTemplate/MessageTemplate';
import intentServices from '../intentServices';
import { MoreHoriz} from '@mui/icons-material';

export function IntentResponseSelector({newIntent,callBack}) {
    const [dialogStatus,setDialogStatus] = useState(false);
    const {AppAlert} = useAlert()
	const [filters,setFilters] = useState(null);
    const [responseMessageList,setResponseMessageList] = useState(null);
    const [loading,setLoadingStatus] = useState(false);


    const getResponseMessageList = () =>{
		if(!filters){
			return false;
		}
		setLoadingStatus(true);
			responseMessageServices.getList(filters).then(res=>{
			setResponseMessageList(res.data);
		}).catch(error=>{
			AppAlert(error.msg,"error")
		}).finally(()=>{
			setLoadingStatus(false)
		})
	}

	useEffect(()=>{
		getResponseMessageList()
	},[filters])


    const addToUserQueryList = (responseId) =>{
        setLoadingStatus(true);
        intentServices.save({userQuery:newIntent,responseMessageId:responseId}).then(res=>{
            AppAlert("New Intent Created");
            callBack();
            setDialogStatus(false);
        }).catch(error=>{
            AppAlert( error.msg, "error")
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    return (
        <>
            <button className='btn btn-primary' onClick={()=>setDialogStatus(true)}>Select Existing Response</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent>
                    <ResponseFilter filters={filters} setFilters={setFilters} />
                </DialogContent>
                <DialogContent>
                    <div style={{height:400}}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Message</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {responseMessageList && responseMessageList.map((responseMessage)=>
                                    <tr key={responseMessage.id} >
                                        <td>{responseMessage.title}</td>
                                        <td><MessageTemplateMinified message={JSON.parse(responseMessage.formValues)} messageType={responseMessage.messageType} /></td>
                                        <td><button className='btn btn-primary' onClick={()=>{addToUserQueryList(responseMessage.id)}} >Select</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn btn-outline-primary'>Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const ResponseSelector = ({callBack}) =>{

    const [dialogStatus,setDialogStatus] = useState(false);
    const {AppAlert} = useAlert()
	const [filters,setFilters] = useState(null);
    const [responseMessageList,setResponseMessageList] = useState(null);
    const [loading,setLoadingStatus] = useState(false);


    const getResponseMessageList = () =>{
		if(!filters){
			return false;
		}
		setLoadingStatus(true);
			responseMessageServices.getList(filters).then(res=>{
			setResponseMessageList(res.data);
		}).catch(error=>{
			AppAlert(error.msg,"error")
		}).finally(()=>{
			setLoadingStatus(false)
		})
	}

	useEffect(()=>{
		getResponseMessageList()
	},[filters])

    const selectResponse = (responseMessage) =>{
        setDialogStatus(false);
        callBack(responseMessage)
    }

    return (
        <>
            <button className='btn btn-primary' onClick={()=>setDialogStatus(true)}><MoreHoriz /></button>
            {/*
            {!selectedResponse &&
                <button className='btn btn-primary' onClick={()=>setDialogStatus(true)}>Select A Response Message</button>
            }
            {selectedResponse && 
                <div className="d-flex justify-content-between">
                    <span className='px-1'><MessageTemplateByResponseId responseId={selectedResponse? selectedResponse.id :null} /></span>
                    <button className='btn btn-warning' onClick={()=>setDialogStatus(true)}><Edit/></button>
                </div>
            }
            */}
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent>
                    <ResponseFilter filters={filters} setFilters={setFilters} />
                </DialogContent>
                <DialogContent>
                    <div style={{height:400}}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Message</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {responseMessageList && responseMessageList.map((responseMessage)=>
                                    <tr key={responseMessage.id} >
                                        <td>{responseMessage.title}</td>
                                        <td><MessageTemplateMinified message={JSON.parse(responseMessage.formValues)} messageType={responseMessage.messageType} /></td>
                                        <td><button className='btn btn-primary' onClick={()=>{selectResponse(responseMessage)}} >Select</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn btn-outline-primary'>Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )

}