import React, { useEffect, useState } from 'react'
import responseMessageServices from '../../responseMessageServices';
import { useAlert } from '../../../../../context/AlertContext';
import { LinearProgress } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

function ChangeFlow({data,callBack}) {
	const {AppAlert} = useAlert();
	const [title,setTitle] = useState(""); 
	

	const [newFlowId,setNewFlowId] = useState("");
	const [saving,setSavingStatus] = useState(false);
	
	const autoFillData = ()=>{
		if(data){
			setTitle(data.title)
			setNewFlowId(data.formValues.newFlowId);
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])

	

	

	const prepareFormValues = () =>{
		let _formValues = {
			newFlowId:newFlowId
		}
		return JSON.stringify(_formValues);
	}

	
	const onSubmit = () =>{

		let payload = {
			"title":title,
			"messageType":"changeFlow",
			"whatsAppResponse": null,
			"formValues":prepareFormValues(),
			
		}

		if(data){
			payload.id = data.id;
		}
		setSavingStatus(true);
		responseMessageServices.save(payload).then(res=>{
			AppAlert(res.msg)
			if(data){
				callBack(data.id);
			} else{
				callBack(res.id)
			}

		}).catch(error=>{
			AppAlert(error.msg,"error");
		}).finally(()=>{
			setSavingStatus(false);
		})
	}

    return (
		<div className='row'>
			<div className='col-md-9'>
				<div className='card'>
					<div className='card-header bg-danger text-white'>Change Flow Form</div>
					<div className='card-body'>
						<div className='row'>
							<div className='col-md-6'>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={title}  onChange={(e)=>setTitle(e.target.value)}/>
									<label>Message Title (For Admin / Manager Use) *</label>
								</div>
							</div>

							<div className='col-md-6'>
								
								
								
							</div>
							<div className='col-md-12'>
								<div className="form-floating">
                  <input type="text" className="form-control" placeholder="" value={newFlowId}  onChange={(e)=>setNewFlowId(e.target.value)}/>
									<label>Select New Flow Id</label>
								</div>
							</div>
						</div>
					</div>
					<div className='card-footer'>
						{saving ? 
							<LinearProgress/>
							:
							<button disabled={title == "" || newFlowId == ""} className='btn btn-primary float-end' onClick={onSubmit} >Save</button>
						}
					</div>
				</div>
			</div>
			<div className='col-md-3'>
				<h3 className='mx-3'>Preview</h3>
				<div className='bg-light border border-dark rounded p-2 mx-3 ps-5' style={{height:"70vh"}}>
					<div className='card'>
						<div className='card-body'>
							{}
						</div>
					</div>
				</div>
			</div>
		</div>
    	
    )
}

export default ChangeFlow