import React, { useState,useEffect,useRef } from 'react'
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import grievanceServices from '../grievanceServices';
import DateSelect from '../../../../components/AppInput/DateSelect';
import AppLayout from '../../../../layout/components/AppLayout';
import AppSelect from '../../../../components/AppInput/AppSelect';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Directions, Download, HighlightOff } from '@mui/icons-material';
import moment from 'moment';
import useStaffList from '../../../../hooks/useStaffList';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent } from '@mui/material';


const RenderLink = (params) =>{
    return (
        <Link target='_blank' to={`/grievance/details/${params.data.id}`}>{params.data.id}</Link>
    )
}

const RenderDirection = (params) =>{
    return (
        <a target='_blank' href={"https://www.google.com/maps/dir//"+params.data.latitude+", "+params.data.longitude} ><Directions/></a>        
    )
}

const RenderImage = (params) =>{

    const [dialogStatus,setDialogStatus] = useState(false);

    return (
        <>
        <div onClick={()=>setDialogStatus(true)}  style={{width:"100%", height:40, backgroundPosition:"center", backgroundSize:"contain", backgroundRepeat:"no-repeat",  backgroundImage: "url(" + params.data.photo  + ")"  }} />

        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="md"
                fullWidth={true}
                >   
                    <span className='float-end' style={{position:"absolute",top:0,right:0}}><button className='btn btn-sm btn-primary p-1' onClick={()=>setDialogStatus(false)} ><HighlightOff/></button></span>
                    <DialogContent>
                        <img type="button" style={{width:"100%"}} onClick={()=>setDialogStatus(true)}  src={params.data.photo} />
                    </DialogContent>
                </Dialog>
        </>
    )
}


function GrievanceDataGridPage() {

    const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);
    const [staffList,getStaffLabel] = useStaffList();

    const [filter,setFilter] = useState(null);
    const [greivanceType,setGreivanceType] = useState(null);
    const [greivanceStatus,setGreivanceStatus] = useState(null);
    const [grievanceList,setGrievanceList] = useState(null);



	const getGreivanceList = () =>{
        if(masters){
            setGrievanceList(null);
            const payload = {
                grievanceType:greivanceType===null ? 0: greivanceType.value,
                grievanceStatus:greivanceStatus===null?0:greivanceStatus.value,
                startDate:filter.startDate,
                endDate:filter.endDate
            }
            grievanceServices.getList(payload).then(res=>{
                setGrievanceList(res.data);
            }).catch(error=>{

            }).finally(()=>{

            })
        }
    }

    useEffect(()=>{
        getGreivanceList();
    },[filter,greivanceType,greivanceStatus,masters])





    const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
            buttons: ['apply'],
        },
        resizable: true,
        width:140,
        filter:true,
        sortable: true,
    });

    const rowClassRules = {
        //'bg-danger text-white' : params => params.data.grievanceStatus === 1,
        'bg-warning' : params => params.data.grievanceStatus === 2,
        'bg-success text-white': params => params.data.grievanceStatus === 3,
        'bg-danger text-white': params => params.data.grievanceStatus === 4,
        
    };

    const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
        let _columnDefs = [
            {width:100, field:"id",headerName: "ID",cellRenderer: RenderLink},
            {field:"complainantName",headerName: "C. Name"},
            {field:"phone",headerName: "C.Phone"},
            {width:100,headerName: "Photo", cellRenderer:RenderImage, field:"photo"},
            {width:180, headerName: "Complainant About", valueGetter: params => getMasterLabel('grievance-type',params.data.grievanceType)},
            {width:220, headerName: "Area",  valueGetter: params => params.data.address},
            {width:100, headerName: "Coordinate",cellRenderer: RenderDirection, valueGetter: params => params.data.latitude+","+params.data.longitude},
            {width:220, headerName: "Address", valueGetter: params => params.data.addressHelper},
            {width:200, headerName: "Assigned To", valueGetter: params => getStaffLabel(params.data.assignedTo)},
            {width:180, headerName: "Reg Date", valueGetter: params => moment(params.data.created_at).format("lll")},
            {width:180, headerName: "Last Updated", valueGetter: params => moment(params.data.updated_at).format("lll")},
            {width:140, headerName: "Status", valueGetter: params => params.data.grievanceStatus +getMasterLabel('grievance-status',params.data.grievanceStatus)},
            
        ];
        setColumnDef(_columnDefs);
    }

    useEffect(()=>{
        if(masters && staffList) {
          prepairColumnDef()
        }
      },[masters,staffList])

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    };


	return (
		<AppLayout>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['grievance-type']]}
                                value={greivanceType}
                                setValue={setGreivanceType}
                                label="Select Catrgory" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['grievance-status']]}
                                value={greivanceStatus}
                                setValue={setGreivanceStatus}
                                label="Select Status" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4'>
                        <div className='py-2'>
                            {grievanceList !== null && <button className=' btn btn-primary float-end mx-2' onClick={onBtnExport} ><Download/> Export</button>}
                            <span className=''><DateSelect filter={filter} setFilter={setFilter} /></span>
                        </div>
                    </div>
				</div>

				<div className='row'>
					
                    <div className="ag-theme-alpine" style={{height:600}}>
                        <AgGridReact
                        ref={gridRef}
                        rowData={grievanceList}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowClassRules={rowClassRules}
                        animateRows={true}
                        onGridReady={getGreivanceList}
                        >
                        </AgGridReact>
                    </div>


				</div>
			</div>
		</AppLayout>
	)
}

export default GrievanceDataGridPage