import { Switch } from '@mui/material'
import React from 'react'

function InputValidation( {
        minLength,
        setMinLength,
        maxLength,
        setMaxLength,
        onlyNumeric,
        setOnlyNumeric
    }){
    return (
        <div className='row'>
            <div className='col-md-12'>
                <hr/>
                <h4>Validations</h4>
            </div>
            <div className='col-md-4'>
                <div className="form-floating mb-3">
                    <input type="number" className="form-control" placeholder=""
                        value={minLength}
                        onChange={(e)=>setMinLength(e.target.value)}
                    />
                    <label >Min Length</label>
                    <small>Leave 0 for no validation</small>
                </div>
            </div>

            <div className='col-md-4'>
                <div className="form-floating mb-3">
                    <input type="number" className="form-control" placeholder=""
                        value={maxLength}
                        onChange={(e)=>setMaxLength(e.target.value)}
                    />
                    <label >Max Length</label>
                    <small>Leave 0 for no validation</small>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='p-2 border rounded'>
                    <span className='mx-2'>Only Numeric</span>
                    <Switch checked={onlyNumeric} onChange={(e)=>setOnlyNumeric(e.target.checked)} />
                </div>
            </div>
        </div>
    )
}

export default InputValidation