import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import chatLogServices from '../chatLogServices';
import { CircularProgress } from '@mui/material';
import IncomingMessageCard from '../components/IncomingMessageCard';
import OutgoingMessageCard from '../components/OutgoingMessageCard';
import moment from 'moment';


function ChatLogPage() {
    const [loadingList,setListLoadingStatus] = useState(false);
    const [loadingMessages,setMessagesLoadingStatus] = useState(false);

    const [selectedDate,setSelectedDate] = useState(moment().format(moment.HTML5_FMT.DATE));

    const [conversationList,setConversationList] = useState(null);
    const [conversationDetails,setConversationDetails] = useState(null);

    const [selectedPhoneNuumber,setSelectedPhoneNumber] = useState(null);

    const getConversationList = () =>{
        setListLoadingStatus(true);
        chatLogServices.getConversationList(selectedDate).then(res=>{
            setConversationList(res.data);
        }).catch(error=>{

        }).finally(()=>{
            setListLoadingStatus(false);
        })
    }

    const getConversationDetails = (phoneNumber) =>{
        setMessagesLoadingStatus(true);
        setSelectedPhoneNumber(phoneNumber)
        chatLogServices.getConversationDetails(phoneNumber).then(res=>{
            setConversationDetails(res.data);
        }).catch(error=>{

        }).finally(()=>{
            setMessagesLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getConversationList();
    },[selectedDate])
    
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <ul className="list-group">
                            <li className="list-group-item bg-dark text-white">
                                <input type="date" className="form-control" value={selectedDate} onChange={(e)=>setSelectedDate(e.target.value)} />
                            </li>
                            {loadingList && <li className="list-group-item text-center"> <CircularProgress/></li>}
                            {!loadingList && conversationList && conversationList.map(conversation=>
                                <li 
                                    className="list-group-item"
                                    type={"button"} key={conversation.phoneNumber}
                                    onClick={()=>getConversationDetails(conversation.phoneNumber)}
                                >
                                    {conversation.phoneNumber}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className='col-md-9'>
                        {selectedPhoneNuumber && loadingMessages &&
                            <div className='card'>
                                <div className='card-body text-center p-5'><CircularProgress/></div>
                            </div>
                        }
                        {selectedPhoneNuumber && !loadingMessages &&
                            <div className='card'>
                                <div className='card-header'>{selectedPhoneNuumber}</div>
                                <div className='card-body'>
                                    {conversationDetails && conversationDetails.map(chat=>
                                        <div key={chat.id}>
                                            {chat.incommingMessage !== null && <IncomingMessageCard message={ JSON.parse(chat.incommingMessage)} dateTime={chat.created_at} />}
                                            {chat.outgoingMessageId !== null && <OutgoingMessageCard message={ JSON.parse(chat.formValues)} messageType={chat.messageType} dateTime={chat.created_at} />}
                                        </div>
                                    )}
                                </div>   
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default ChatLogPage