import React, { useEffect, useState } from 'react'

function MuteConfig({flowConfigList,muteConfig,setMuteConfig}) {

    


    const operatorList = [
        {value:"eq",label:"Equal to"},
        {value:"lt",label:"Less Then"},
        {value:"gt",label:"Greater Then"},
    ]

    

    const handleConfigChange = (key,value) =>{
        let _muteConfig = {...muteConfig};
        _muteConfig[key] = value;
        setMuteConfig(_muteConfig)
    }
    

    return (
        <div className='row'>
            <div className='col-md-12'>
                <h4>Show if </h4>
            </div>
            <div className='col-md-4'>
                <select className="form-control" value={ muteConfig?  muteConfig.fieldNo:""} onChange={(e)=>handleConfigChange( "fieldNo", e.target.value)} >
                    <option value={""}>--Select One--</option>
                    {flowConfigList?.map((field,i)=>
                        <option key={i} value={i}>{field.fieldLabel}</option>
                    )}
                </select>
            </div>
            <div className='col-md-4'>
                <select className="form-control" value={muteConfig ? muteConfig.operator : ""} onChange={(e)=>handleConfigChange("operator",e.target.value)} >
                    <option value={""}>--Select One--</option>
                    {operatorList.map(operator=>
                        <option key={operator.value} value={operator.value}>{operator.label}</option>
                    )}
                </select>
            </div>
            <div className='col-md-4'>
                <input type={"text"}  className="form-control"
                    value={muteConfig ? muteConfig.val : ""} onChange={(e)=>handleConfigChange("val",e.target.value)}
                />
            </div>

            <div className='row'>
                {/* JSON.stringify(muteConfig) */} 
            </div>
        </div>
    )
}

export default MuteConfig