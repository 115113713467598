
const prepareTextResponse = (fieldLabel) =>{
    let _responseMessage = {
        "preview_url": true,
        "body": fieldLabel
    }
    return _responseMessage;
}

const prepareLocationResponse = (fieldLabel) =>{
    let _responseMessage = {
        "type": "location_request_message",
        "body": {
            "text":fieldLabel
        },
        "action": {
            "name": "send_location"
        }
    }
    return _responseMessage;
}


const prepareListResponse = ( fieldLabel,optionList) =>{
    let _responseMessage = {
        "type": "list",
        "body": {
            "text":fieldLabel
        },
    }
    let _optionList = [];
    optionList.forEach(option => {
        let _option = {
            "id" :option.optionId,
            "title": option.optionTitle,
        }
        if(option.optionDescription !==""){
            _option.description = option.optionDescription
        }
        _optionList.push(_option);
    });
    let _action = {
        "button" : "Select",  
        "sections":[
            {
                "title": fieldLabel, 
                "rows":_optionList
            }
        ]
    }
    _responseMessage.action = _action;
    return _responseMessage;
}

const prepareReplyResponse = (fieldLabel,optionList) =>{
    let _responseMessage = {
        "type": "button",
        "body": {
            "text":fieldLabel
        },
    }
    let _optionList = [];
    optionList.forEach(option => {
        let _option = {
            "type" :"reply",
            "reply": {
                "id" :option.optionId,
                "title": option.optionTitle
            }
        }
        _optionList.push(_option);
    });
    let _action = {
        "buttons":_optionList
    }
    _responseMessage.action = _action;
    return _responseMessage;
}


const prepareWhatsAppResponse = (fieldLabel,optionList,messageType) =>{
    let _whatsAppResponse = {
        "messaging_product": "whatsapp",
        "recipient_type": "individual",
    }

    if(messageType == "text" ){
        _whatsAppResponse.type = "text";
        _whatsAppResponse.text = prepareTextResponse(fieldLabel)
    }
    
    if(messageType == "interactiveList" ){
        _whatsAppResponse.type = "interactive";
        _whatsAppResponse.interactive = prepareListResponse(fieldLabel,optionList)
    }

    if(messageType == "interactiveReply"){
        _whatsAppResponse.type = "interactive";
        _whatsAppResponse.interactive = prepareReplyResponse(fieldLabel,optionList)
    }

    if(messageType == "locationRequest"){
        _whatsAppResponse.type = "interactive";
        _whatsAppResponse.interactive = prepareLocationResponse(fieldLabel)
    }

    if(messageType == "imageRequest" ){
        _whatsAppResponse.type = "text";
        _whatsAppResponse.text = prepareTextResponse(fieldLabel)
    }

    if(messageType == "audioRequest" ){
        _whatsAppResponse.type = "text";
        _whatsAppResponse.text = prepareTextResponse(fieldLabel)
    }

    return JSON.stringify(_whatsAppResponse);
}


const prepareTextFormValues = ($fieldLabel) =>{
    let _formValues = {
        messageBody:$fieldLabel
    }
    return JSON.stringify(_formValues);
}

const prepareLocationFormValues = ($fieldLabel) =>{
    let _formValues = {
        messageBody:$fieldLabel
    }
    return JSON.stringify(_formValues);
}

const prepareListFormValues = (fieldLabel,optionList) =>{
    let _formValues = {
        "bodyText":fieldLabel,
        "optionList" : optionList,
        "menuButtonTitle":"Select",
        "optionListTitle":fieldLabel,
    }
    return JSON.stringify(_formValues);
}

const prepareReplyFormValues = (fieldLabel,optionList) =>{
    let _formValues = {
        "bodyText":fieldLabel,
        "optionList" : optionList
    }
    return JSON.stringify(_formValues);
}


const prepareFormValues = (fieldLabel,optionList,messageType) =>{
    
    if(messageType == "text" ){
        return prepareTextFormValues(fieldLabel);
    }
    if(messageType == "interactiveList" ){
        return prepareListFormValues(fieldLabel,optionList);
    }
    if(messageType == "interactiveReply"){
        return prepareReplyFormValues(fieldLabel,optionList);
    }
    if(messageType == "locationRequest"){
        return prepareLocationFormValues(fieldLabel);
    }

    if(messageType == "imageRequest"){
        return prepareTextFormValues(fieldLabel);
    }

    if(messageType == "audioRequest"){
        return prepareTextFormValues(fieldLabel);
    }
}



const flowResponseServices = {
    prepareWhatsAppResponse,
    prepareFormValues
}
export default flowResponseServices;