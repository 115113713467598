
import React, { useState,useEffect } from 'react'
import flowServices from '../flowServices';
import { useAlert } from '../../../../context/AlertContext';
import { CircularProgress } from '@mui/material';
import { Dialog, DialogActions, DialogContent } from '@mui/material';



export default function FlowGenerateForm({data,callBack}) {

    const {AppAlert} = useAlert()
    const [flowTitle,setFlowTitle] = useState("");
    const [flowType,setFlowType] = useState(2);
    const [methodName,setMethodName] = useState("");
    const [endPoint,setEndPoint] = useState("");
    

    const[headerName,setHeaderName] = useState("");
    const[headerValue,setHeaderValue] = useState("");
    const [requestHeaders,setRequestHeaders] = useState(['Content-Type:application/json']);

    const [saving,setSavingStatus] = useState("");
    const [dialogStatus, setDialogStatus] = useState(false);


    const autoFillData = ()=>{
		if(data){
			setFlowTitle(data.flowTitle);
            setFlowType(data.flowType);
            setMethodName(data.methodName);
            setEndPoint(data.endPoint);
            if(data.requestHeaders !== null){
                setRequestHeaders( JSON.parse(data.requestHeaders));
            } else {
                setRequestHeaders(['Content-Type:application/json'])
            }
            
            
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])


    const addHeader = () =>{
        let _requestHeaders = [...requestHeaders];
        _requestHeaders = [..._requestHeaders,`${headerName}:${headerValue}`];
        setRequestHeaders(_requestHeaders);
        setHeaderName("");
        setHeaderValue("");
    }

    const removeHeader = (i) =>{
        let _requestHeaders = [...requestHeaders];
        _requestHeaders.splice(i,1)
        setRequestHeaders(_requestHeaders)
    }


    const onSubmit = () =>{
        let payload = {
            flowTitle:flowTitle,
            flowType:flowType,
        }

        if(flowType == 1){
            payload.methodName = methodName;
        }
        if(flowType == 2){
            payload.endPoint = endPoint;
            payload.requestHeaders = JSON.stringify(requestHeaders);
        }

        if(data){
            payload.id = data.id;
        }
        setSavingStatus(true)
        flowServices.save(payload).then(res=>{
            AppAlert("New Flow Generated");
            setDialogStatus(false);
            callBack();
        }).catch(error=>{
            AppAlert("Someting","error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    return (
        <>
            {data === null ?
                <button className="btn btn-primary" onClick={()=>setDialogStatus(true)}>Create New</button>
                :
                <button className="btn btn-primary" onClick={()=>setDialogStatus(true)}>Edit</button>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" placeholder=""
                                    value={flowTitle}
                                    onChange={(e)=>setFlowTitle(e.target.value)}
                                />
                                <label >Flow Name</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-floating">
                                <select className="form-select" onChange={(e)=>setFlowType(e.target.value)}>
                                    <option value="1">Method</option>
                                    <option value="2">Rest Request</option>
                                </select>
                                <label className="form-label">Selet Type</label>
                            </div>
                        </div>
                    </div>
                    {flowType == 1 &&
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder=""
                                        value={methodName}
                                        onChange={(e)=>setMethodName(e.target.value)}
                                    />
                                    <label >Method Name</label>
                                </div>
                            </div>
                        </div>
                    }

                    {flowType == 2 &&
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder=""
                                        value={endPoint}
                                        onChange={(e)=>setEndPoint(e.target.value)}
                                    />
                                    <label >End Point</label>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <h3>Select Headers</h3>
                                <button className='btn btn-outline-primary btn -sm' onClick={()=>setHeaderName("X-Requested-With")} >X-Requested-With</button>
                            </div>
                            <div className='col-md-5'>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder=""
                                        value={headerName}
                                        onChange={(e)=>setHeaderName(e.target.value)}
                                    />
                                    <label >Header Name</label>
                                </div>
                            </div>

                            <div className='col-md-5'>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder=""
                                        value={headerValue}
                                        onChange={(e)=>setHeaderValue(e.target.value)}
                                    />
                                    <label >Header Value</label>
                                </div>
                            </div>

                            <div className='col-md-2'>
                                <button className='btn btn-primary' onClick={addHeader}>Add</button>
                            </div>
                            
                            
                            {requestHeaders && requestHeaders.map((header,i)=>
                                <div key={i} className='col-md-10'>
                                    {header}
                                    <button className='btn btn-danger btn-sm' onClick={()=>removeHeader(i)} ></button>
                                </div>
                            )}
                            

                            


                        </div>
                    }
                    
                    
                </DialogContent>
                <DialogActions>
                <div className='row'>
                        <div className='col-md-6'>
                            {! saving &&

                                <button 
                                    disabled={flowTitle==="" || endPoint===""}
                                    className='btn btn-primary' type='button' onClick={onSubmit}
                                >{data ? "Update" : "Generate"}</button>
                            }
                            {saving &&  <CircularProgress/> }
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
