import {useState,useEffect} from 'react'



function useCordinates() {
    const [cordinates,setCordinates] = useState(null);
	useEffect(()=>{
		if(navigator.geolocation){

			navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
				if (permissionStatus.state === 'denied') {
				  alert('Please allow location access.');
				  window.location.href = "app-settings:location";
				} else {
				  	//navigator.geolocation.getCurrentPosition(success, error);
				  	navigator.geolocation.watchPosition(_position=>{
						setCordinates(_position.coords);
					})
				}
			  });
			
			
		}
	},[])
    return cordinates;
}

export default useCordinates