import React,{useState,useEffect} from 'react'
import { Link, useParams } from 'react-router-dom';
import AppLayout from '../../../../layout/components/AppLayout';
import responseMessageServices from '../responseMessageServices';
import { useAlert } from '../../../../context/AlertContext';
import {MessageTemplate} from '../components/MessageTemplate/MessageTemplate';
import IntentFormComponent from '../components/IntentFormComponent';
import { IconButton, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';

function ResponseMessageDetailsPage() {
	const [messageType,setMessageType] = useState(null);
	const {id} = useParams();
	const [data,setData] = useState(null);
	const {AppAlert} = useAlert();
	useEffect(()=>{
		if(id){
			responseMessageServices.getById(id).then(res=>{
				let _data = {id:id,title:res.data.title}
				_data.formValues = JSON.parse(res.data.formValues)
				setData(_data);
				setMessageType(res.data.messageType);
			}).catch(error=>{
				AppAlert(error.msg,"error")
			})
		}
	},[id])

  	return (
    	<AppLayout>
			<div className='container-fluid'>
				<div className='row mt-3'>
					<div className='col-md-9'>
						<IntentFormComponent responseId={id} />
					</div>
					<div className='col-md-3'>
						<h3 className='mx-3'>Preview</h3>
						<div className='bg-light border border-dark rounded p-2 mx-3 ps-5' style={{height:"70vh"}}>
							<Tooltip title="Edit Message">
								<Link to={"/admin/response-message/form/"+id}>
									<IconButton><Edit/></IconButton>
								</Link>
							</Tooltip>
							<MessageTemplate message={data?.formValues} messageType={messageType} />
						</div>
					</div>
					
				</div>
			</div>
		</AppLayout>
  	)
}

export default ResponseMessageDetailsPage