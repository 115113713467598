import React,{useState} from 'react'
import { CameraAlt, Image, Send } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material'
import Webcam from "react-webcam";
import api from '../../../../api/api'
import { useAlert } from '../../../../context/AlertContext';

function ChatImageInput({callBack}) {

  const {AppAlert} = useAlert();
  const [dialogStatus, setDialogStatus] = useState(false);

  const isImage = (file) =>{
    if(file.type.match('image.*')){
        return true;
    }
    return false;
  }

  const uploadToServer = (formData) =>{
    api.post("admin/simulator/chat/file",formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }).then( (res)=>{  
        console.log("here in upload local success",res.data);
        callBack(res.data.filePath);
        setDialogStatus(false);
    }).catch( (error) => {
        console.log(error);
    })
  }

  const uploadImage = (e) =>{
    if(!isImage(e.target.files[0])){
        AppAlert("Invalid File Type","error");
        return false;
    }
    const chatUserInfo =JSON.parse(sessionStorage.getItem("chatUserInfo")); 
    let formData = new FormData();
    formData.append('fileType', "image");
    formData.append('phone',chatUserInfo.phoneNo)
    formData.append('tmpFile', e.target.files[0]);
    uploadToServer(formData);
  }

  // Camera Image Work
  const [cameraDialogStatus,setCameraDialogStatus] = useState(false);
  const [facingMode,setFacingMode] = useState("user")
  const [imageUri,setImageUri] = useState(null)
  const swicthCamera = () =>{
      if(facingMode == "user"){
          setFacingMode("environment")
      }
      if(facingMode == "environment"){
          setFacingMode("user")
      }
  }

  const videoConstraints = {
      width: 800,
      height: 800,
      facingMode: facingMode,
  };


  /*****   Camera Upload Work In Progress */
  const  DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }
  const uploadCameraImage = () => {
    const chatUserInfo =JSON.parse(sessionStorage.getItem("chatUserInfo")); 
    let formData = new FormData();
    formData.append('fileType', "image");
    formData.append('extension', "jpg" );
    formData.append('phone',chatUserInfo.phoneNo)
    formData.append('tmpFile',DataURIToBlob(imageUri));
    setCameraDialogStatus(false);
    uploadToServer(formData);
  }

  return (
    <>
      <a className="dropdown-item" href="#image" onClick={()=>setDialogStatus(true)}><Image /> Image</a>
      <Dialog
        open={dialogStatus}
			  keepMounted
			  onClose={()=>setDialogStatus(false)}
			  maxWidth="sm"
			  fullWidth={true}
      >
        <DialogContent>
          <div className="row">
              <div className="col-md-12 text-center">
                  <input type='file'
                      onChange={ (e)=>{ uploadImage(e) } } 
                      accept="image/png, image/gif, image/jpeg"
                      id="chatImage"
                      className='d-none'
                  ></input>
                  <label htmlFor='chatImage' className='btn btn-primary mx-2 mt-2'>Upload From Gallery</label>
                  <button type="button" className="btn btn-dark mt-2" onClick={()=>setCameraDialogStatus(true)}><CameraAlt/> Open Camera</button>
              </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={cameraDialogStatus}
        keepMounted
        onClose={()=>setCameraDialogStatus(false)}
        fullScreen={true}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{padding:0}}>
          <div className="row">
            <div className="col-md-12 text-center">
              
              {imageUri}

              {cameraDialogStatus == true && imageUri!==null &&
                
                <div className="card">
                  <img className="card-img-top" src={imageUri} alt="Card image cap" />
                  <div className="card-body">
                    
                    <button className='btn btn-danger m-2' onClick={()=>setImageUri(null)} >Cancel</button>
                    <button className='btn btn-success m-2' onClick={uploadCameraImage} ><Send/>Send</button>
                  </div>
                </div>

              }

              {cameraDialogStatus == true && imageUri===null &&
                <Webcam
                  audio={false}
                  height={350}
                  screenshotFormat="image/jpeg"
                  width={350}
                  videoConstraints={videoConstraints}
                >
                  {({ getScreenshot }) => (
                      <><br/>
                      <button
                          className="btn btn-primary m-2"
                          onClick={() => {
                              let imageSrc = getScreenshot();
                              setImageUri(imageSrc);
                              //uploadCameraImage(imageSrc);
                              //setCameraDialogStatus(false);
                          }}
                      
                      >
                          Capture photo
                      </button>
                      <button type="button" onClick={swicthCamera} className="btn btn-dark m-2">Switch Camera</button>
                      <button type="button" onClick={()=>setCameraDialogStatus(false)} className="btn btn-outline-primary m-2">Cancel</button>
                      </>
                  )}
                </Webcam>
              }
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ChatImageInput