import React from 'react'
import { Route, Routes } from 'react-router'
import ResponseMessageListPage from '../app/admin/response/pages/ResponseMessageListPage'
import ResponseMessageFormPage from '../app/admin/response/pages/ResponseMessageFormPage'
import MediaLibraryPage from '../app/admin/response/pages/MediaLibraryPage'
import WhatsAppUserRoutes from './WhatsAppUserRoutes'
import WhatsAppAdminLandingPage from '../app/dashboard/pages/WhatsAppAdminLandingPage'
import { useAuth } from '../context/AuthContext'
import ResponseMessageGridPage from '../app/admin/response/pages/ResponseMessageGridPage'
import ResponseMessageDetailsPage from '../app/admin/response/pages/ResponseMessageDetailsPage'
import AdminChatSumulatorPage from '../app/admin/Simulator/pages/AdminChatSumulatorPage'
import FlowListPage from '../app/admin/flow/page/FlowListPage'
import FlowConfigPage from '../app/admin/flow/page/FlowConfigPage'





function WhatsAppAdminRoutes() {
  const {authUser} = useAuth();
  return (
    <>
      <Routes>
        {/* WhatsApp Admin */}
        {authUser.role == 3 && <Route path="/" element={ <WhatsAppAdminLandingPage /> }></Route>  }
        <Route path="/admin/response-message/list" element={ <ResponseMessageListPage /> }></Route>
        <Route path="/admin/response-message/grid" element={ <ResponseMessageGridPage /> }></Route>
        <Route path="/admin/response-message/form" element={ <ResponseMessageFormPage /> }></Route>
        <Route path="/admin/response-message/form/:id" element={ <ResponseMessageFormPage /> }></Route>
        <Route path="/admin/response-message/details/:id" element={ <ResponseMessageDetailsPage /> }></Route>
        <Route path="/admin/chat-simulator" element={ <AdminChatSumulatorPage /> }></Route>
        <Route path="/admin/media-library" element={ <MediaLibraryPage /> }></Route>


        <Route path="/admin/flow/list" element={ <FlowListPage /> }></Route>
        <Route path="/admin/flow/config/:flowId" element={ <FlowConfigPage /> }></Route>

      </Routes>
      <WhatsAppUserRoutes/>
    </>
  )
}

export default WhatsAppAdminRoutes