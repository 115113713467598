import React,{useEffect, useState} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { Link} from 'react-router-dom';
import FlowGenerateForm from '../components/FlowGenerateForm';
import flowServices from '../flowServices';
import { Settings } from '@mui/icons-material';


function FlowListPage() {
    

    const [flowList,setFlowList] = useState(null);

    const getFlowList = () =>{
        flowServices.getList().then(res=>{
            setFlowList(res.data);
        }).catch(error=>{

        })
    }
    

    useEffect(()=>{
        getFlowList();
    },[])

    return (
        <AppLayout>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <span className='float-end'><FlowGenerateForm data={null} callBack={getFlowList} /></span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {flowList && 
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Flow Name</th>
                                        <th>End Point</th>
                                        <th>Initiate With</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {flowList.map(flow=>
                                        <tr>
                                            <td>{flow.flowTitle}</td>
                                            <td>{flow.endPoint} {flow.methodName}</td>
                                            <td></td>
                                            <td>
                                                <FlowGenerateForm data={flow} callBack={getFlowList} />
                                                <Link to={"/admin/flow/config/"+flow.id} className='ms-3 btn btn-outline-primary' ><Settings/></Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default FlowListPage