import React,{useEffect, useState} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import {useParams} from 'react-router-dom';
import flowQueryServices from '../flowQueryServices';
import FlowQueryForm from '../components/FlowQueryForm';
import FlowDetailsComponent from '../components/FlowDetailsComponent';


function FlowConfigPage() {
    
    const {flowId} = useParams()

    const [flowConfigList,setFlowConfigList] = useState(null);
    const getflowConfigList = () =>{
        flowQueryServices.getListByFlowId(flowId).then(res=>{
            setFlowConfigList(res.data);
        }).catch(error=>{

        })
    }

    useEffect(()=>{
        getflowConfigList();
    },[])

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <FlowDetailsComponent flowId={flowId} />
                    </div>
                    <div className='col-md-9'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <span className='float-end'><FlowQueryForm flowId={flowId} data={null} callBack={getflowConfigList} flowConfigList={flowConfigList} /></span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {flowConfigList && 
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Field Name</th>
                                                <th>Label</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {flowConfigList.map(field=>
                                                <tr key={field.id}>
                                                    <td>{field.fieldName}</td>
                                                    <td>{field.fieldLabel}</td>
                                                    <td>
                                                        <FlowQueryForm data={field} flowId={flowId} callBack={getflowConfigList} flowConfigList={flowConfigList} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </AppLayout>
    )
}

export default FlowConfigPage