import { List, RadioButtonUnchecked, Reply } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React,{useEffect, useState} from 'react'
import responseMessageServices from '../../responseMessageServices'

export const TextMessage = ({message}) =>{
  return (
	<>
		<div className='card'>
			<div className='card-body'>
				{message.messageBody}
			</div>
		</div>
	</>
  )
}

export const TextMessageMinified = ({message}) =>{
	return (
	  <>  
		<span>{message.messageBody}</span>  
	  </>
	)
}



export const LocationRequest = ({message,callBack}) =>{
	const [dialogStatus, setDialogStatus] = useState(false);
	const handleClick = (option) =>{
		if(callBack!==undefined){
			callBack(option,"interactiveList")
			setDialogStatus(false)
		} else{
			console.log("no callBack defined")
			setDialogStatus(false)
		}
	}
	return (
	  <>
		  <div className='card'>
			  <div className='card-body'>
				  {message.messageBody}
			  </div>
		  </div>
	  </>
	)
  }
  
export const LocationRequestMinified = ({message}) =>{
	return (
		<>  
			<span>{message.messageBody}</span>  
		</>
	)
}

export const ImageRequest = ({message,callBack}) =>{
	const [dialogStatus, setDialogStatus] = useState(false);
	const handleClick = (option) =>{
		if(callBack!==undefined){
			callBack(option,"interactiveList")
			setDialogStatus(false)
		} else{
			console.log("no callBack defined")
			setDialogStatus(false)
		}
	}
	return (
	  <>
		  <div className='card'>
			  <div className='card-body'>
				  {message.messageBody}
			  </div>
		  </div>
	  </>
	)
  }
  
export const ImageRequestMinified = ({message}) =>{
	return (
		<>  
			<span>{message.messageBody}</span>  
		</>
	)
}



export const InteractiveReply = ({message,callBack}) =>{
	const handleClick = (option) =>{
		if(callBack!==undefined){
			callBack(option,"interactiveReply")
		} else{
			console.log("no callBack defined")
		}
	}
	return (
		<div className='card'>
			<div className='card-body'>
				<h5>{message.headerText}</h5>
				<h6>{message.bodyText}</h6>
				<h6 className='text-muted'>{message.footerText}</h6>
				{message.optionList && message.optionList.map((option,i)=>
					<button key={i} className='btn btn-dark bg-white text-dark m-2'  onClick={()=>handleClick(option)}>{option.optionTitle}</button>
				)}
			</div>
		</div>
	)
}

export const InteractiveReplyMinified = ({message}) =>{
	const [dialogMessageStatus,setDialogMessageStatus] = useState(false);
  	return (
    <>
		<span>
			{message.headerText}  {message.headerText !== "" && ","} {message.bodyText}
		</span>
		<button type="button" className='ms-2 btn btn-outline-primary btn-sm py-0' onClick={()=>setDialogMessageStatus(true)}>Interactive Reply<Reply/></button>
		<Dialog
			open={dialogMessageStatus}
			keepMounted
			onClose={()=>setDialogMessageStatus(false)}
			maxWidth="sm"
			fullWidth={true}
		>
			<InteractiveReply message={message} />
		</Dialog>
    </>
  )
}


export const InteractiveList = ({message,callBack}) =>{
	const [dialogStatus, setDialogStatus] = useState(false);
	const handleClick = (option) =>{
		if(callBack!==undefined){
			callBack(option,"interactiveList")
			setDialogStatus(false)
		} else{
			console.log("no callBack defined")
			setDialogStatus(false)
		}
	}
	return (
		<div className='card'>
			<div className='card-body'>
				<h5>{message.headerText}</h5>
				<h6>{message.bodyText}</h6>
				<h6 className='text-muted'>{message.footerText}</h6>
			</div>  
			<div className='card-footer text-center'>
				<button className='btn btn-outline-primary' onClick={()=>setDialogStatus(true)} ><List/> {message.menuButtonTitle}</button>		
			</div>
			<Dialog
				open={dialogStatus}
				keepMounted
				onClose={()=>setDialogStatus(false)}
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle>{message.optionListTitle}</DialogTitle>
				<DialogContent>
					<ul className="list-group">
					{message.optionList && message.optionList.map(option=>
					<li className="list-group-item d-flex justify-content-between align-items-center" key={option.id} onClick={()=>handleClick(option)} >
						<span>
						<div className='fs-5'>{option.optionTitle} </div>
						{option.optionDescription !== "" && <div>{option.optionDescription}</div> }
						</span>
						<span><RadioButtonUnchecked /></span>
						</li>
					)}
					</ul>
				</DialogContent>
			</Dialog>
		</div>
	)
}

export const InteractiveListMinified = ({message,minified}) =>{
    
	const [dialogMessageStatus,setDialogMessageStatus] = useState(false);
    return (
		<>
			<span className='ms-1'>
				{message.headerText}{message.headerText !== "" && ","} {message.bodyText}
			</span>
			<button type="button" className='ms-2 btn btn-outline-primary btn-sm py-0' onClick={()=>setDialogMessageStatus(true)}>Interactive List <List/></button>
			<Dialog
				open={dialogMessageStatus}
				keepMounted
				onClose={()=>setDialogMessageStatus(false)}
				maxWidth="sm"
				fullWidth={true}
			>
				<InteractiveList message={message} />
			</Dialog>
		</>	
    )
}


export const MessageTemplate = ({message,messageType, callBack}) =>{
	return (
		<>
			{messageType === "text" && <TextMessage message={message} />}
            {messageType === "interactiveReply" && <InteractiveReply message={message} callBack={callBack} /> }
            {messageType === "interactiveList" && <InteractiveList message={message} callBack={callBack} /> }
			{messageType === "locationRequest" && <LocationRequest message={message} callBack={callBack} />}
			{messageType === "imageRequest" && <ImageRequest message={message} callBack={callBack} />}
		</>
	)
}


export const MessageTemplateMinified = ({message,messageType}) =>{
	return (
		<>
			{messageType === "text" && <TextMessageMinified message={message}  />}
            {messageType === "interactiveReply" && <InteractiveReplyMinified message={message} /> }
            {messageType === "interactiveList" && <InteractiveListMinified message={message}  /> }
			{messageType === "locationRequest" && <LocationRequestMinified message={message} />}
			{messageType === "imageRequest" && <ImageRequestMinified message={message} />}
		</>
	)
}

export const MessageTemplateByResponseId = ({responseId}) =>{
	const [responseDetails,setResponseDetails] = useState(null);
	const getResponseDetails = () =>{
		responseMessageServices.getById(responseId).then(res=>{
			setResponseDetails(res.data);
		}).catch(error=>{

		})
	}

	useEffect(()=>{
		if(responseId !== null){
			getResponseDetails()
		}
	},[responseId])

	return(
		<>
			{responseDetails &&
			<MessageTemplateMinified message={ JSON.parse(responseDetails.formValues)} messageType={responseDetails.messageType} />
			}
		</>
	)
}

