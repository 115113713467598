import React, { useState,useEffect,useRef } from 'react'
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import grievanceServices from '../grievanceServices';
import DateSelect from '../../../../components/AppInput/DateSelect';
import AppLayout from '../../../../layout/components/AppLayout';
import AppSelect from '../../../../components/AppInput/AppSelect';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Directions, Download, HighlightOff } from '@mui/icons-material';
import moment from 'moment';
import useStaffList from '../../../../hooks/useStaffList';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent } from '@mui/material';
import epfGrievanceServices from '../epfGrievanceServices';


const RenderLink = (params) =>{
    return (
        <Link target='_blank' to={`/epf/grievance/details/${params.data.id}`}>{params.data.id}</Link>
    )
}





function EpfGrievanceDataGridPage() {

    const [masters,getMasterLabel] = useSystemMasterList(['epf-grievance-type','epf-grievance-category','grievance-status']);
    const [staffList,getStaffLabel] = useStaffList();

    const [filter,setFilter] = useState(null);
    const [epfGrievanceType,setEpfGreivanceType] = useState(null);
    const [greivanceStatus,setGreivanceStatus] = useState(null);
    const [grievanceList,setGrievanceList] = useState(null);



	const getGreivanceList = () =>{
        if(masters){
            setGrievanceList(null);
            const payload = {
                epfGrievanceType:epfGrievanceType===null ? 0: epfGrievanceType.value,
                grievanceStatus:greivanceStatus===null?0:greivanceStatus.value,
                startDate:filter.startDate,
                endDate:filter.endDate
            }
            epfGrievanceServices.getList(payload).then(res=>{
                setGrievanceList(res.data);
            }).catch(error=>{

            }).finally(()=>{

            })
        }
    }

    useEffect(()=>{
        getGreivanceList();
    },[filter,epfGrievanceType,greivanceStatus,masters])





    const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
            buttons: ['apply'],
        },
        resizable: true,
        width:140,
        filter:true,
        sortable: true,
    });

    const rowClassRules = {
        //'bg-danger text-white' : params => params.data.grievanceStatus === 1,
        'bg-warning' : params => params.data.grievanceStatus === 2,
        'bg-success text-white': params => params.data.grievanceStatus === 3,
        'bg-danger text-white': params => params.data.grievanceStatus === 4,
        
    };

    const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
        let _columnDefs = [
            {width:100, field:"id",headerName: "ID",cellRenderer: RenderLink},
            {field:"customerName",headerName: "C. Name"},
            {field:"phone",headerName: "C.Phone"},
            {width:180, headerName: "Complainant About", valueGetter: params => getMasterLabel('epf-grievance-type',params.data.epfGrievanceType)},
            {width:180, headerName: "Grievance Category", valueGetter: params => getMasterLabel('epf-grievance-category',params.data.epfGrievanceCategory)},
            {width:180, headerName: "Reg Date", valueGetter: params => moment(params.data.created_at).format("lll")},
            {width:180, headerName: "Last Updated", valueGetter: params => moment(params.data.updated_at).format("lll")},
            {width:140, headerName: "Status", valueGetter: params => getMasterLabel('grievance-status',params.data.grievanceStatus)},
            
        ];
        setColumnDef(_columnDefs);
    }

    useEffect(()=>{
        if(masters && staffList) {
          prepairColumnDef()
        }
      },[masters,staffList])

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    };


	return (
		<AppLayout>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['epf-grievance-type']]}
                                value={epfGrievanceType}
                                setValue={setEpfGreivanceType}
                                label="Select Catrgory" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['grievance-status']]}
                                value={greivanceStatus}
                                setValue={setGreivanceStatus}
                                label="Select Status" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4'>
                        <div className='py-2'>
                            {grievanceList !== null && <button className=' btn btn-primary float-end mx-2' onClick={onBtnExport} ><Download/> Export</button>}
                            <span className=''><DateSelect filter={filter} setFilter={setFilter} /></span>
                        </div>
                    </div>
				</div>

				<div className='row'>
					
                    <div className="ag-theme-alpine" style={{height:600}}>
                        <AgGridReact
                        ref={gridRef}
                        rowData={grievanceList}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowClassRules={rowClassRules}
                        animateRows={true}
                        onGridReady={getGreivanceList}
                        >
                        </AgGridReact>
                    </div>


				</div>
			</div>
		</AppLayout>
	)
}

export default EpfGrievanceDataGridPage